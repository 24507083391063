<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('agentGarden')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="agentGarden"
                  ref="agentGarden"
                  label-width="150px"
                  class="agentGarden"
                  size="mini"
                >
                  <el-row>
                     <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="选择代理商" prop="agentCode">
                        <el-select
                          v-model="agentGarden.agentCode"
                          placeholder="请选择代理商"
                          class="wp100"
                          value-key="agentCode"
                        >
                          <el-option
                            v-for="item in agentList"
                            :key="item.agentCode"
                            :label="item.agentName"
                            :value="item.agentCode"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-col :sm="24" :md="24" :lg="12" style="display: none">
                        <el-form-item label="id" prop="id">
                          <el-input v-model="agentGarden.id"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-form-item label="园所名称" prop="gardenName">
                        <el-input v-model="agentGarden.gardenName"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="园所编码" prop="gardenCode">
                        <el-input v-model="agentGarden.gardenCode"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="园所地址" prop="address">
                        <el-input v-model="agentGarden.address"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

export default {
  
  data() {
      return{
        // 当前页数
        currPage: 1,
        pageSize:10,
        seeThis: 0,
        loading: true,
        id:'',
        agentGarden: {
            id:"",
            agentCode:"",
            agentName:"",
            agentNo:"",
            createBy:"",
            createTime:"",
            updateBy:"",
            updateTime:"",
            agentNo:"",
            gardenCode:"",
            gardenName:"",
            gardenNo:"",
            address:"",
            referralCode:"",
            orderCount:"",
            orderAmount:"",
        },
        agent:'',
       agentList:[],
      };
  },

  methods:{
      getagentList () {
      this.$http
        .post("/agent/queryList",{ parentId: 0} )
        .then(response=>{
          if (response.data.code == 200) {
            this.agentList = response.data.data;
            console.log(' that.agentList1:', this.agentGarden.agentCode);
            //设置默认选中
            this.agentList.forEach((item)=>{
                if( this.agentGarden.agentCode==item.agentCode){
                    this.agent=item.agentCode
                }
            })
          
          }
        });
    },
    goIndex(){
        this.$router.push({
        path: "/agentGarden",
        query: {currPage:this.currPage,pageSize:this.pageSize},
      });
    },
    submitForm(formName){
      var that=this;
          //  this.agentList.forEach(item=>{
          //    if(item.agentCode==that.agent){ 
          //      that.agentGarden.agentName = item.agentName;
          //      that.agentGarden.agentCode = item.agentCode;
          //       that.agentGarden.agentNo =item.agentNo;
          //    }
          //  })
      // that.agentGarden.agentName = item.agentName;
      //   that.agentGarden.agentCode = item.agentCode;
      //   that.agentGarden.agentNo =item.agentNo;
      console.log('agent',that.agentGarden)
      that.$http.post("/agentGarden/save", that.agentGarden).then(response => {
			if(response.data.code==200){
				 that.$notify.success({
					title: "提示",
					message: "保存成功",
					showClose: true,
				 })
          this.goIndex()
			}else{
				that.$notify.error({
                    title: "提示",
                    message: response.data.message,
                    showClose: true,
                });
			}
          
      })
      //   .catch(function(error){
      //     that.$notify.info({
      //       title: "提示",
      //       message: "保存失败",
      //       showClose: true,
      //     });

      // })
     
    },
    loadBaseInfo(){
      if(this.id!=undefined&&this.id!=""){
         this.$http.post("/agentGarden/queryOne", {id:this.id}).then(response=> {
          if (response.data.code == 200) {
            this.total = response.data.data.count;
            this.agentGarden = response.data.data;
             
          }
        });
      }

    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height> scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height> scrollHeight) {
        this.seeThis = 3;
      }

    },
  },
 
  created() {
     if (this.$route.query.id != undefined&&this.$route.query.id != "") {
      this.id =this.$route.query.id;
    }
    this.currPage=this.$route.query.currPage;
    this.pageSize=this.$route.query.pageSize;
    this.loadBaseInfo();
 this.getagentList();
   
  },
  mounted() {
      var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
</style>
